import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/matthew-sokolowski.jpg";
  const fullName = `Matthew Evan Sokołowski`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84104358480?pwd=NjZmREptMFI4ZXJXWEc0S0FUNzh2dz09";
  const zoomPhoneNumber = "(312) 626-6799";
  const zoomMeetingId = "841 0435 8480";
  const zoomMeetingPasscode = "760620";
  const birthDate = new Date(1967, 4 - 1, 12);
  const deathDate = new Date(2021, 1 - 1, 12);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 24);
  const memorialTime = "4pm EST";
  const inviteText = `Matthew Evan Sokołowski passed away January 12th, 2021. In lieu of a physical ceremony, Matt’s family invites you to a virtual service celebrating his life.`;
  const inviteChild = <></>;
  const obituary = `Matthew Evan Sokołowski passed away on January 12, 2021 at the Cleveland Clinic-Main Campus, surrounded by his family either in person or, due to the pandemic, virtually.  Matt was born on April 12, 1967 in Portage, Michigan.  He attended Portage Northern High School before his family moved to Bourbonnais, Illinois, where he graduated in 1985 from Bradley-Bourbonnais Community High School.  Matt attended the University of Illinois, the alma mater of his parents and sister, Betsy.  He was a member of the Delta Tau Delta Fraternity and was referred to as the “Unknown Delt.”   He met his wife, Mitzi, the first girl Matt ever asked out on a date, during this time.   After graduating in 1989. Matt began his career in pharmaceutical sales and clinical marketing at Pfizer-Roerig Division, and Genentech, and finally joined Merck & Co. Inc. in 1998.   Matt excelled in sales and marketing, and quickly advanced to management roles, including Regional Manager in 2014 with Merck Animal Health.  He was an award-winning sales manager and described as both a “World Class Coach” and exceptional people developer. His teams loved, respected and worked hard for him.
  \nMatt was an endearing, even irreverent, soul. He was goofy and loved all kinds of humor, appropriate or not (a primary attribute for a Sokołowski). Bevis and Butthead inspired him and he often quoted them while in character.   Matt made silly faces, laughed at himself and made others laugh. He was a good sport and often found himself in hilarious situations, such as letting his 6-year-old niece apply makeup and place curlers in his hair, dressing up in costume for his boys’ birthday parties, and having his hair braided; and we all know how much Matt loved his long hair!  Many of us can hear Matt’s voice echoing the following “Mattisms”:  ”For the Love of God;” “Be Brief, Be Bright and Be Gone;” “You’re all adults, I don’t have to babysit;” “What you do everyday matters;” and “Trust me.” 
  \nMatt loved classic rock, hard rock and guitar riffs. He was a virtuoso on the air guitar and played with ferocity. As a passionate Detroit Redwings fan, he was obsessed with hockey, and all three of his boys played on teams in Michigan and in Ohio. Matt was frequently observed informing the referees of mistaken calls in both states.  Matt also loved fishing and boating.  Many fishing excursions ended at the grocery to purchase fish for dinner. Cars were another love of Matt’s and he had many, but his prized possession was his beloved vintage Saab.  Matt had planned to purchase a new black Dodge Charger (V8 Super-Charged Hemi) to add to his collection.
  \nAt his core, Matt cared deeply about and was devoted to his family and friends.  He wore his heart on his sleeve.  He was proud, profoundly loyal, hardworking, dedicated, and stubborn about pretty much everything.  He was taken from us far too soon and will be missed deeply.
  \nMatt was preceded in death by his father, Dr. James Hale Sokołowski and is survived by his wife, Mitzi, their three boys, AJ, Evan and Ian, his mother, Barbara Sokołowski (Atlanta, GA), sisters Dian (Michael) Deimler (Atlanta, GA ), and Betsy Sokołowski (Marietta, GA), brother-in-law Tony (Kristi) Ficker (Centennial, CO), sister-in-law, Mary (Craig) Lewis (Elwood, IL), former brother-in-law Alex Popovich, as well as 7 nephews and 3 nieces.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      poster: "/images/people/matthew-sokolowski-poster.png",
      url: "https://storage.googleapis.com/afterword-static/recordings/Matthew%20Soko%C5%82owski.mp4",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
    >
      <p>
        In lieu of flowers, donations may be made in Matt’s memory to the{" "}
        <a href="https://liverfoundation.org/" target="_blank" rel="noreferrer">
          American Liver Foundation
        </a>{" "}
        or{" "}
        <a href="https://bestfriends.org/" target="_blank" rel="noreferrer">
          Best Friends Animal Society
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
